import styled from "@emotion/styled";

export const SubTextContainer = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome500,
    theme.splus3.typography.smallBold,
    {
        display: "flex",
        marginTop: 8,
        columnGap: 12,
    }
]));

export const SubTextDiv = styled.div([
    {
        display: "flex",
        columnGap: 4,
        alignItems: "center",
    }
]);

export const CompareTextContainer = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome4,
    theme.splus3.typography.small,
    {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 8,
        lineHeight: "100%",

        [theme.splus3.breakpoints.mobile]: [
            {
                marginTop: 22,
            }
        ],        
    }
]));