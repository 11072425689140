import styled from "@emotion/styled";
import { useEffect, useRef } from "react";

interface FaqAnswerDivProps {
  isExpanded: boolean | null;
}

const FaqAnswerDiv = styled.div<FaqAnswerDivProps>(({ theme, isExpanded }) => ([
  theme.typography.faqAnswerText,
  theme.colors.background.faqAnswer,
  isExpanded === null && {
    transition: "",
    transitionDelay: "0s",
  },
  isExpanded === true && {
    transition: "max-height 0.2s ease-in, margin-top 0.2s ease",
    transitionDelay: "0.1s",
  },
  isExpanded === false && {
    transition: "max-height 0.2s ease-out, margin-top 0.2s ease",
    transitionDelay: "0s",
  },
  {
    padding: "0 10px",
    overflow: "hidden",
    maxHeight: isExpanded == false ? 0 : 3000,

    p: {
      marginBottom: 8,
    },

    span: {
      bold: [
        theme.typography.bold14,
        {
          textDecoration: "none",
        }
      ],
    },

    ul: {
      paddingLeft: 20,
      margin: 0,
    },

    li: {
      marginLeft: 0,
      marginBottom: 8,
    },

    a: [
      theme.colors.grey,
    ],
  },
]));

const FaqAnswerSubDiv = styled.div(({ theme }) => ([
  theme.colors.background.primary,
  {
    padding: 20,
    margin: 20,
    border: "1px solid #e3e3e3",
    borderRadius: 5,
  },
]));

const FaqAccordionItem: React.FC<{ title: string, isExpanded: boolean | null, onClick: () => void }> = ({ title, isExpanded, onClick, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isExpanded == true) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [isExpanded]);

  return (
    <div ref={ref} className="accordion__item">
      <div className={`accordion__heading ${isExpanded ? "accordion-active" : ""}`} onClick={onClick}>
        <span className="accordion__title">
          {title}
        </span>
        <span className={`accordion__icon ${isExpanded ? "accordion__icon--turn" : ""}`} />
      </div>
      {isExpanded != false && (
        <FaqAnswerDiv isExpanded={isExpanded}>
          <FaqAnswerSubDiv>
            {children}
          </FaqAnswerSubDiv>
        </FaqAnswerDiv>
      )}
    </div>
  )
}

export default FaqAccordionItem;