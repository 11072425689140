import styled from "@emotion/styled";
import MainCard from "../elements/MainCard";
import Accordion from "../elements/Accordion";
import { HomeInfoCardId, InfoData, getHomeInfoCardData } from "../infoData";
import { useEffect, useState } from "react";

const RootDiv = styled(MainCard)(({ theme }) => ([
    {
        display: "none",
        flexDirection: "column",
        rowGap: 12,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "flex"
        },
    }
]));

const HeaderDiv = styled.div([
    {
        display: "flex",
        columnGap: 12,
        alignItems: "center",
    }
]);

const HeaderText = styled.div([
    props => props.theme.splus3.typography.font16Lite,
    props => props.theme.splus3.foreground.primary,
    {
        flexGrow: 10,
        lineHeight: "137.5%",
    }
]);

const ContentDiv = styled.div([
    props => props.theme.splus3.typography.font12Lite,
    props => props.theme.splus3.foreground.monochrome600,
]);

interface InfoCardMobileProps {

}

const InfoCardMobile: React.FC<InfoCardMobileProps> = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null | undefined>(undefined);

    const getItemHeader = (item: InfoData, _: boolean) => {
        return (
            <HeaderDiv>
                <img src={item.image} />
                <HeaderText>{item.text}</HeaderText>
            </HeaderDiv>
        );
    }

    const getItemContent = (item: InfoData) => {
        return <ContentDiv>{item.hintContent}</ContentDiv>
    }

    const onItemClick = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    }

    useEffect(() => {
        // This is needed to make the accordion fully open when JavaScript is disabled in the browser
        setExpandedIndex(null);
    }, []);

    return (
        <RootDiv>
            <Accordion<InfoData> items={[
                getHomeInfoCardData(HomeInfoCardId.InternetSecurity),
                getHomeInfoCardData(HomeInfoCardId.Desktop),
                getHomeInfoCardData(HomeInfoCardId.Bundles)
            ]}
                expandedIndex={expandedIndex}
                getItemHeader={getItemHeader}
                getItemContent={getItemContent}
                onItemClick={onItemClick}
            />
        </RootDiv>
    );
}

export default InfoCardMobile;