import styled from "@emotion/styled";
import MainCard from "../elements/MainCard";
import CommonContainer from "../elements/CommonContainer";
import { InfoItemJson, InfoSubitemJson, getInfoData } from "./infoUtils";
import markdownRender from "../../../helper/markdownRender";
import { fixImageUrl } from "../../../helper/utils";
import * as R from "ramda";
import InfoCardDesktop from "./InfoCardDesktop";
import InfoCardMobile from "./InfoCardMobile";

const ContentPartDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",

    marginTop: 40,
    marginBottom: 78,
    marginLeft: "8.33vw",
    marginRight: "8.33vw",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: {
      marginTop: 8,
      marginLeft: 16,
      marginRight: 16,
    },
  }
]));

const TextH2Div = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.h2Bold,
  {
    marginBottom: 56,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.foreground.monochrome500,
      theme.splus3.typography.h1m,
      {
        marginBottom: 32,
      }
    ],
  }
]));

const TextH3Div = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.h3,
  {
    marginBottom: 36,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.foreground.monochrome500,
      theme.splus3.typography.font24Bold,
      {
        marginBottom: 24,
      }
    ],
  }
]));

const TextH4Div = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.font24Bold,
  {
    marginBottom: 24,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.foreground.monochrome500,
      theme.splus3.typography.font16Bold,
      {
        marginBottom: 16,
      }
    ],
  }
]));

const BodyTextDiv = styled.div(({ theme }) => ([
  props => props.theme.splus3.foreground.primary,
  props => props.theme.splus3.typography.default,
  {
    marginBottom: 40,

    p: [
      {
        marginTop: 0,
        marginBottom: 14,
      }
    ],

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.foreground.monochrome500,
      theme.splus3.typography.small,
      {
        marginBottom: 20,        
      }
    ],
  }
]));

const ImageContainerDesktop = styled(MainCard)(({ theme }) => ([
  {
    alignSelf: "center",
    maxWidth: 720,
    padding: 20,
    marginBottom: 56,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.background.white,
      {
        marginBottom: 32,
      }
    ],
  }
]));

interface InfoPageProps {
}

const InfoPage: React.FC<InfoPageProps> = () => {
  const infoData = getInfoData();

  return (
    <CommonContainer>
      <ContentPartDiv>
        <TextH2Div>
          {infoData.title}
        </TextH2Div>
        <BodyTextDiv dangerouslySetInnerHTML={{ __html: markdownRender(infoData.content) }} />
        <ImageContainerDesktop><img width="100%" src={fixImageUrl(infoData.image)} /></ImageContainerDesktop>
        {infoData.items.map((item, index) => <InfoPageItem key={index} title={item.title} items={item.items} />)}
        <InfoCardDesktop />
        <InfoCardMobile />
      </ContentPartDiv>
    </CommonContainer>
  );
}

interface InfoPageItemProps extends InfoItemJson {

}

const InfoPageItem: React.FC<InfoPageItemProps> = ({ title, items }) => {
  return (
    <>
      <TextH3Div>{title}</TextH3Div>
      {!R.isNil(items) && items.map((item, index) => <InfoPageSubitem key={index} title={item.title} content={item.content} />)}
    </>
  );
}

interface InfoPageSubitemProps extends InfoSubitemJson {

}

const InfoPageSubitem: React.FC<InfoPageSubitemProps> = ({ title, content }) => {
  return (
    <>
      <TextH4Div>{title}</TextH4Div>
      <BodyTextDiv dangerouslySetInnerHTML={{ __html: markdownRender(content) }} />
    </>
  );
}

export default InfoPage;
