import styled from "@emotion/styled";
import { ClickableDiv } from "./ClickableDiv";

const RootDiv = styled.div([
    props => props.theme.splus3.background.white,
    props => props.theme.splus3.shadow.containerWide,
    {
        borderRadius: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 12,
        paddingBottom: 12,
    }
]);

const HeaderDiv = styled.div([
    {
        display: "flex",
        columnGap: 12,
        alignItems: "center",
        cursor: "pointer",
    }
]);

interface ContentDivProps {
    isExpanded: boolean | null;
}

const ContentDiv = styled.div<ContentDivProps>(({ theme, isExpanded }) => ([
    isExpanded === null && {
        transition: "",
        transitionDelay: "0s",
    },
    isExpanded === true && {
        transition: "max-height 0.2s ease-in, margin-top 0.2s ease",
        transitionDelay: "0.1s",
    },
    isExpanded === false && {
        transition: "max-height 0.2s ease-out, margin-top 0.2s ease",
        transitionDelay: "0s",
    },
    {
        overflow: "hidden",
        maxHeight: isExpanded ? 1500 : 0,
        marginTop: isExpanded ? 16 : 0,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            marginTop: isExpanded ? 12 : 0,

            img: [
                {
                    maxHeight: 512,
                }
            ]
        },
    },
]));

interface AccordionItemProps {
    header: unknown;
    isExpanded: boolean | null;
    onClick: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ header, isExpanded, onClick, children }) => {
    const expandedD = "M4.19526 22.1382C3.93491 21.8779 3.93491 21.4558 4.19526 21.1954L15.5286 9.86209C15.7889 9.60174 16.2111 9.60174 16.4714 9.86209L27.8047 21.1954C28.0651 21.4558 28.0651 21.8779 27.8047 22.1382C27.5444 22.3986 27.1223 22.3986 26.8619 22.1382L16 11.2763L5.13807 22.1382C4.87772 22.3986 4.45561 22.3986 4.19526 22.1382Z";
    const collapsedD = "M27.8047 9.86177C28.0651 10.1221 28.0651 10.5442 27.8047 10.8046L16.4714 22.1379C16.2111 22.3983 15.7889 22.3983 15.5286 22.1379L4.19526 10.8046C3.93491 10.5442 3.93491 10.1221 4.19526 9.86177C4.45561 9.60142 4.87772 9.60142 5.13807 9.86177L16 20.7237L26.8619 9.86177C27.1223 9.60142 27.5444 9.60142 27.8047 9.86177Z";

    return (
        <ClickableDiv onClick={onClick} role="combobox">
            <RootDiv>
                <HeaderDiv>
                    <div style={{ flexGrow: 10 }}>{header}</div>
                    <div style={{ width: 32 }}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d={isExpanded ? expandedD : collapsedD} fill="#E60000" />
                        </svg>
                    </div>
                </HeaderDiv>
                <ContentDiv isExpanded={isExpanded}>
                    {isExpanded && children}
                </ContentDiv>
            </RootDiv>
        </ClickableDiv>
    )
}

export default AccordionItem;