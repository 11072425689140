import React, { useState } from "react";
import styled from "@emotion/styled";
import AccordionItem from "./AccordionItem";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: 10,

        maxWidth: 1000,
    }
]);

interface AccordionProps<T> {
    items: T[];
    expandedIndex: number | null | undefined; // undefined means all items are expanded
    getItemHeader: (item: T, isExpanded: boolean) => JSX.Element;
    getItemContent: (item: T) => JSX.Element;
    onItemClick: (index: number) => void;
}

function Accordion<T>({ items, expandedIndex, getItemHeader, getItemContent, onItemClick }: AccordionProps<T>): JSX.Element {
    return (
        <RootDiv>
            {items.map((item, index) => {
                const isExpanded = expandedIndex === undefined || expandedIndex === index;
                return (
                    <AccordionItem key={index}
                        header={getItemHeader(item, isExpanded)}
                        isExpanded={expandedIndex === null ? isExpanded : (isExpanded ? true : null)}
                        onClick={() => onItemClick(index)}>
                        {getItemContent(item)}
                    </AccordionItem>
                )
            })}
        </RootDiv>
    );
}

export default Accordion;
