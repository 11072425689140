import styled from "@emotion/styled";
import { ClickableDiv } from "./ClickableDiv";

import checkBoxMarkSvg from "../../../../assets/icons/splus3/check-box-mark.svg";

export type CheckedType = false | true | "indeterminate";

const BaseDiv = styled.div(({ theme }) => ([
    theme.splus3.shadow.checkBox,
    {
        width: 20,
        height: 20,
        borderRadius: 4,
    }
]));

const UncheckedDiv = styled(BaseDiv)(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.border.checkBox,
    {
        width: 18,
        height: 18,
    }
]));

const CheckedDiv = styled(BaseDiv)(({ theme }) => ([
    theme.splus3.background.vodafoneRed,
]));

const IndeterminateDiv = styled(BaseDiv)(({ theme }) => ([
    theme.splus3.background.vodafoneRed,
    {
        opacity: 0.6,
    }
]));

interface CheckBoxProps {
    checked: CheckedType;
    onClick: () => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onClick }) => {
    return (
        <ClickableDiv onClick={onClick} preventDefault={true} role="checkbox">
            {
                (() => {
                    switch (checked) {
                        case false: return <UncheckedDiv />
                        case true: return <CheckedDiv><img src={checkBoxMarkSvg.src} width={20} height={20} alt="check mark" /></CheckedDiv>
                        case "indeterminate": return <IndeterminateDiv />
                    }
                })()
            }
        </ClickableDiv>
    );
}

export default CheckBox;